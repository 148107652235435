import { claimerAdrs, akuAdrs } from "../../constants/chain"
import { Contract } from "starknet"
import ERC20Abi from "../../constants/abi/ERC20.json"
import ClaimerAbi from "../../constants/abi/Claimer.json"
import BigNumber from "bignumber.js";

export default class Getter {
  static async hasWalletClaimed(selectedAddress, provider) {
    const contract = new Contract(ClaimerAbi, claimerAdrs, provider);
    const res = await contract.hasClaimedWallet(selectedAddress);
    console.log('hasWalletClaimed : ', res);
    return res;
  }

  static async hasClaimStarted(provider) {
    const contract = new Contract(ClaimerAbi, claimerAdrs, provider);
    const res = await contract.hasClaimStarted();
    console.log('hasClaimStarted : ', res);
    return res;
  }

  static async isIdClaimed(id, provider) {
    const contract = new Contract(ClaimerAbi, claimerAdrs, provider);
    const res = await contract.isClaimedId(id);
    console.log('isIdClaimed : ', res);
    return res;
  }

  static async getAkuBalance(selectedAddress, provider){
    try {
      const contract = new Contract(ERC20Abi, akuAdrs, provider);
      const res = new BigNumber(await contract.balanceOf(selectedAddress));
      let decimals = new BigNumber("1000000000000000000")
      // console.log('getAkuBalance : ', res.dividedBy(decimals).toFixed(0));
      return res.dividedBy(decimals).toFixed(0);
    }
    catch(error){
      console.log('getAkuBalance ', error.message)
      return 0;
    }
  }
}