// Navbar.js

import React, { useState } from 'react';
import './Navbar.css';
import { HashLink as Link } from 'react-router-hash-link';

import twitterIcon from '../assets/xIconOrangeBrown.png';
import telegramIcon from '../assets/telegramIconOrangeBrown.png';
import coingeckologo from '../assets/coingeckologoOrangeBrown2.png';
// import farcasterIcon from '../assets/farcasterIconOrangeBrown.png';
import dexscreenerIcon from '../assets/dexscreenerOrangeBrown.png';

export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`navbarContainer ${menuOpen ? 'menuOpen' : ''}`}>
      <div className='menuIconAndLinksContainer'>
        <div className='menuIcon' onClick={toggleMenu}>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
        <div className='linksAndSocialsContainer'>
          <div className='linksContainer'>
            {/* <Link className='navbarLink' to='#trade'>
              Buy
            </Link> */}
            <Link className='navbarLink' to='#tokenomics'>
              Tokenomics
            </Link>
            <Link className='navbarLink' to='#roadmap'>
              Roadmap
            </Link>
            <Link className='navbarLink' to='#integrations'>
              Integrations
            </Link>
            <Link className='navbarLink' to='#art'>
              Art
            </Link>
            <a className='navbarLink' target="_blank" rel="noopener noreferrer" href='https://weebpunks.com/'>
              NFTs
            </a>
          </div>
          <div className='navbarSocialsContainer'>
            <a target="_blank" rel="noopener noreferrer" href='https://twitter.com/AkamaruStarknet'>
              <img className='navbarIcon' src={twitterIcon} alt='twitterIcon' />
            </a>
            {/* <a target="_blank" rel="noopener noreferrer" href='https://warpcast.com/akamaru'>
              <img className='navbarIcon' src={farcasterIcon} alt='farcasterIcon' />
            </a> */}
            <a target="_blank" rel="noopener noreferrer" href='https://t.me/AkamaruStarknet'>
              <img className='navbarIcon' src={telegramIcon} alt='telegramIcon' />
            </a>
            <a target="_blank" rel="noopener noreferrer" href='https://www.coingecko.com/en/coins/akamaru'>
              <img className='navbarIconRound' src={coingeckologo} alt='coingeckoIcon' />
            </a>
            {/* <a target="_blank" rel="noopener noreferrer" href='https://dexscreener.com/starknet/0x02d2e13758c4ed963182630acf475528b071e3aea6eb6f98e7aaa949a4ff75d7'>
              <img className='navbarIconRound' src={dexscreenerIcon} alt='dexscreenerIcon' />
            </a> */}
        </div>
      </div>
      </div>
    </div>
  );
}
