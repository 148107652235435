import { claimerAdrs, akuAdrs } from "../../constants/chain"
import { Contract } from "starknet"
import ERC20Abi from "../../constants/abi/ERC20.json"
import ClaimerAbi from "../../constants/abi/Claimer.json"

export default class Sender {
  static async claim(account, provider, leaf, proof, quantityIndex) {
    try{
      const contract = new Contract(ClaimerAbi, claimerAdrs, account);
      const tx = await contract.claim(proof, leaf, quantityIndex);
      let res = await provider.waitForTransaction(tx.transaction_hash);
      return true
    }
    catch(error){
      console.log('claim ', error.message)
      return false;
    }
  }
  // async handleLockNft(nftId, timeframe, account, provider) {
  //   try{
  //     const locker = new Contract(lockerAbi, lockerAdrs, account);
  //     const tx = await locker.lock(nftId, timeframe, Math.floor(Math.random() * 10000000));
  //     await account.waitForTransaction(tx.transaction_hash);
  //   }
  //   catch(error){console.log(error.message)}
  // }
}