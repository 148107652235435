export const currentChain = "SN_MAIN";
export const currentChainName = "Mainnet";
export const currentChainNetwork = "mainnet-alpha";
export const claimerAdrs = "0x0688c0ebe3c967dddad6fe219a9bd3abcbfa7c7bb9f2a290ce86d6d309f32f2e";
export const akuAdrs = "0x0137dfca7d96cdd526d13a63176454f35c691f55837497448fad352643cfe4d4";

// export const currentChain = "SN_GOERLI";
// export const currentChainName = "Testnet";
// export const currentChainNetwork = "testnet";
// export const claimerAdrs = "0x0643f515bf6eade4c8d7559a991665b427a1a6b65390e5505b5189ea20c37c48";
// export const akuAdrs = "0x040b91de6dba3bd8168057f153d002bfa8b9e61d321c16b4f20c132939f036e1";
